<template>
  <div>
    <b-table
      class="lvj-table"
      :items="listModel"
      :fields="fields"
      bordered
      striped
      hover
      caption-top
    >
      <template #table-caption>
        <search-input
          v-model="searchText"
          full
          :is-loading-search="isLoadingSearch"
          @enter="search"
        />
      </template>
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          @click="openCreateModal"
        >
          NOVO
        </b-button>
      </template>

      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="openEditModal(row.item)"
        >
          <b-icon icon="pencil" />
        </b-button>

        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveModal(row.item.id)"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>

    <b-button v-if="showLoadMore" block class="mb-5" @click="getMore">
      <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
      CARREGAR MAIS
    </b-button>

    <b-modal
      id="modal-center"
      :title="form.id ? 'Editar Modelo' : 'Nova Modelo'"
      centered
      hide-footer
      v-model="showCreateModal"
      @show="loadOptions"
    >
      <b-form @submit="onSubmit">
        <form-file
          v-model="file"
          :link-to-open="form.thumbnail_link"
          label="Image do Veiculo:"
          :placeholder="form.thumbnail_link || 'Selecione uma imagem'"
          accept="image/jpeg, image/png, image/webp"
          icon="card-image"
          @input="uploadImage"
        />

        <form-select
          v-model="form.marca_id"
          :options="options"
          label="Marca"
          text-field="descricao"
          required
        />

        <form-input
          v-model="form.descricao"
          label="Descrição:"
          placeholder="Digite a descrição da modelo"
          required
        />

        <b-button type="submit" variant="primary">SALVAR</b-button>
      </b-form>
    </b-modal>

    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja apagar modelo"
      @confirm="onDeleteModel"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import FormFile from "../../components/form/FormFile.vue";
import FormInput from "../../components/form/FormInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import CustomModal from "../../components/shared/CustomModal.vue";
import SearchInput from "../../components/shared/SearchInput.vue";
import http from "../../plugins/http";

export default {
  name: "listModels",
  components: { FormInput, FormSelect, FormFile, CustomModal, SearchInput },
  data() {
    return {
      searchText: "",
      listModel: [],
      file: null,
      form: {
        marca_id: 0,
        descricao: "",
        thumbnail_link: "",
      },
      options: [],
      showCreateModal: false,
      showRemoveModal: false,
      modelId: 0,
      isLoading: false,
      isLoadingSearch: false,
      page: 2,
      showLoadMore: true,
      fields: [
        {
          key: "marca.descricao",
          label: "Marca",
          sortable: true,
        },
        {
          key: "descricao",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    openCreateModal() {
      this.form = {
        marca_id: 0,
        descricao: "",
        thumbnail_link: "",
      };
      this.showCreateModal = true;
    },
    async onCreateModel() {
      try {
        const { data } = await http.post("/brand/model", this.form);
        this.listModel = [...this.listModel, data];

        this.setToastedSuccess({ message: "Modelo criada com sucesso" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao criar modelo, tente novamente mais tarde",
        });
      }
    },
    openEditModal(model) {
      this.form = model;
      this.showCreateModal = true;
    },
    async onUpdateModel() {
      try {
        const { data } = await http.put(
          `/brand/model/${this.form.id}`,
          this.form
        );
        const index = this.listModel.findIndex(
          (model) => model.id === this.form.id
        );
        const modelsCopy = [...this.listModel];
        modelsCopy.splice(index, 1, data);

        this.listModel = modelsCopy;

        this.setToastedSuccess({ message: "Modelo editada com sucesso" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao editar modelo, tente novamente mais tarde",
        });
      }
    },
    openRemoveModal(id) {
      this.modelId = id;
      this.showRemoveModal = true;
    },
    async onDeleteModel() {
      try {
        await http.delete(`/brand/model/${this.modelId}`);

        this.listModel = this.listModel.filter(
          (model) => model.id !== this.modelId
        );

        this.setToastedSuccess({ message: "Modelo removida com sucesso" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao removida modelo, tente novamente mais tarde",
        });
      }
      this.showRemoveModal = false;
    },
    async onSubmit(event) {
      event.preventDefault();

      if (this.form.id) {
        await this.onUpdateModel();
      } else {
        await this.onCreateModel();
      }

      this.showCreateModal = false;
    },
    async loadOptions() {
      const { data } = await http.get("/brand/combobox");
      this.options = data;
    },
    async search() {
      this.isLoadingSearch = true;
      this.page = 2;

      const { data } = await http.get("/brand/model", {
        params: {
          search: this.searchText,
        },
      });

      this.listModel = data;
      this.isLoadingSearch = false;

      this.showLoadMore = data.length === 20;
    },
    resetSearch() {
      this.searchInput = "";
      this.page = 2;
      this.getMore();
    },
    async getMore() {
      this.isLoading = true;
      const { data } = await http.get("/brand/model", {
        params: {
          page: this.page,
          search: this.searchText || undefined,
        },
      });

      this.listModel = [...this.listModel, ...data];
      this.isLoading = false;

      if (data.length === 20) {
        this.page = this.page + 1;
      } else {
        this.showLoadMore = false;
      }
    },
    async uploadImage() {
      try {
        const formData = new FormData();
        formData.append("image", this.file);
        const { data } = await http.post("/brand/model/thumbnail", formData);
        this.form.thumbnail_link = data.thumbnailLink;
        this.setToastedSuccess({ message: "Imagem enviada com sucesso" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao enviar imagem, tente novamente mais tarde",
        });
      }
    },
  },
  async created() {
    const [{ data }] = await Promise.all([
      http.get("/brand/model"),
      this.loadOptions(),
    ]);

    if (data.length !== 20) this.showLoadMore = false;
    this.listModel = data;
  },
};
</script>
