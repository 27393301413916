<template>
  <b-form-group :label="label">
    <b-input-group>
      <b-input-group-prepend v-if="icon" is-text>
        <b-icon :icon="icon" />
      </b-input-group-prepend>

      <b-form-select
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :options="options"
        :text-field="textField"
        :value-field="valueField"
        @change="$emit('change', $event)"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Array,
    },
    textField: {
      type: String,
      default: "text",
    },
    valueField: {
      type: String,
      default: "id",
    },
  },
};
</script>

<style></style>
