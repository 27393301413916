<template>
  <div>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="6">
            <div
              class="combobox-wrap"
              style="margin-bottom: 1rem"
              v-click-outside="closeCombobox"
            >
              <label for="inputModelo">Modelo:</label>
              <div class="input-group no-margin-bottom">
                <input
                  v-model="searchModel"
                  id="inputModelo"
                  type="text"
                  class="form-control"
                  @focus="() => (comboboxFocus = true)"
                  @click.stop="() => openCombobox()"
                  @input="
                    () => {
                      if (!comboboxFocus) {
                        comboboxFocus = true;
                      }
                    }
                  "
                />

                <div v-if="form.modeloId" class="input-group-append">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    @click.stop="
                      () => {
                        searchModel = '';
                        form.modeloId = 0;
                      }
                    "
                  >
                    <b-icon-x />
                  </button>
                </div>
              </div>

              <div
                class="combobox-list"
                :style="{ display: comboboxFocus ? 'block' : 'none' }"
              >
                <ul class="ul-reset">
                  <li
                    v-for="model in formattedModels"
                    :key="model.id"
                    class="combobox-item"
                    @click.stop="
                      () => {
                        form.modeloId = model.id;
                        searchModel = model.text;
                        comboboxFocus = false;
                      }
                    "
                  >
                    {{ model.text }}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <form-select
                  v-model="form.eixo"
                  :options="[
                    {
                      id: 'D',
                      text: 'Dianteiro',
                    },
                    {
                      id: 'T',
                      text: 'Traseiro',
                    },
                    {
                      id: 'A',
                      text: 'Dianteiro e Traseiro',
                    },
                    {
                      id: 'X',
                      text: 'Auxiliar',
                    },
                    {
                      id: 'K',
                      text: 'Trucado',
                    },
                    {
                      id: 'C',
                      text: 'Traçado',
                    },

                    {
                      id: 'E1',
                      text: '1º Eixo',
                    },
                    {
                      id: 'E2',
                      text: '2º Eixo',
                    },
                    {
                      id: 'E3',
                      text: '3º Eixo',
                    },
                  ]"
                  label="Eixo:"
                  required
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.anoInicial"
                  label="Ano Inicial:"
                  required
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.anoFinal"
                  label="Ano Final:"
                  required
                />
              </b-col>
              <b-col align-self="end">
                <b-button
                  class="mb-3"
                  type="button"
                  block
                  @click="() => addModel()"
                >
                  Adicionar
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-col align-self="end">
            <b-row>
              <b-col>
                <search-input
                  v-model="search"
                  :is-loading-search="isLoadingSearch"
                  placeholder="Pesquise um produto"
                  initial-focus
                  full
                  @enter="onSearch"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table
              :items="modelsToShow"
              :fields="modelHeaders"
              :tbody-tr-class="rowClass"
              empty-text="Não há modelos para mostrar"
              show-empty
              bordered
              striped
              hover
            >
              <template #cell(actions)="row">
                <b-button
                  variant="danger"
                  size="sm"
                  class="mr-2"
                  @click="openRemoveModelDialog(row.item.modeloId)"
                >
                  <b-icon icon="x-circle" />
                </b-button>
              </template>
            </b-table>
          </b-col>

          <b-col>
            <b-table
              :items="productsToShow"
              :fields="productHeadersToShow"
              empty-text="Não há produtos para mostrar"
              show-empty
              bordered
              striped
              hover
            >
              <template #cell(posicao)="row">
                <form-input v-model="row.item.posicao" />
              </template>
              <template #cell(actions)="row">
                <b-button
                  variant="success"
                  size="sm"
                  class="mr-2"
                  @click="addProduct(row.item)"
                  v-if="!row.item.remove"
                >
                  <b-icon icon="check" />
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  class="mr-2"
                  @click="removeProduct(row.item.id)"
                  v-else
                >
                  <b-icon icon="x-circle" />
                </b-button>
              </template>
            </b-table>

            <b-button
              v-if="showButton && search"
              type="submit"
              variant="primary"
              block
              @click="() => onShowMore()"
            >
              CARREGAR MAIS
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-button variant="primary" @click="onSubmit">SALVAR</b-button>

    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja remover modelo"
      @confirm="removeAtributo"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import CustomModal from "../../components/shared/CustomModal.vue";
import SearchInput from "../../components/shared/SearchInput.vue";
import http from "../../plugins/http";

export default {
  components: { FormInput, FormSelect, SearchInput, CustomModal },
  name: "AddToProduct",
  data() {
    return {
      search: "",
      searchModel: "",
      comboboxFocus: false,
      form: {
        modeloId: 0,
        modelText: "",
        eixo: "",
        anoInicial: "",
        anoFinal: "",
        models: [],
        products: [],
      },
      modelOptions: [],
      isLoadingSearch: false,
      showRemoveModal: false,
      idToRemove: 0,
      productsFromApi: [],
      page: 2,
      showButton: false,
      modelHeaders: [
        {
          key: "modelText",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "eixoText",
          label: "Eixo",
          sortable: true,
        },
        {
          key: "anoInicial",
          sortable: true,
        },
        {
          key: "anoFinal",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      productHeaders: [
        {
          key: "codigo_produto",
          label: "Código",
          sortable: true,
        },
        {
          key: "descricao_completa",
          label: "Produto",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    formattedModels() {
      return this.modelOptions
        .map((model) => ({
          ...model,
          text: `${model.descricao} ${model.marca.descricao}`,
        }))
        .filter((model) =>
          model.text.toLowerCase().includes(this.searchModel.toLowerCase())
        );
    },
    modelsToShow() {
      return this.form.models.map((model) => {
        let invalid = false;
        let eixoText = "";

        if (model.anoInicial && model.anoFinal) {
          invalid = model.anoInicial > model.anoFinal;
        }

        if (!model.anoInicial && model.anoFinal) {
          invalid = true;
        }

        if (model.anoInicial && model.anoInicial < 1900) {
          invalid = true;
        }

        if (model.anoFinal && model.anoFinal < 1900) {
          invalid = true;
        }

        if (model.eixo === "A") {
          eixoText = "Ambos";
        }

        if (model.eixo === "D") {
          eixoText = "Dianteiro";
        }

        if (model.eixo === "T") {
          eixoText = "Traseiro";
        }

        if (model.eixo === "X") {
          eixoText = "Auxiliar";
        }

        if (model.eixo === "K") {
          eixoText = "Trucado";
        }

        if (model.eixo === "C") {
          eixoText = "Traçado";
        }

        if (model.eixo === "E1") {
          eixoText = "1º Eixo";
        }

        if (model.eixo === "E2") {
          eixoText = "2º Eixo";
        }

        if (model.eixo === "E3") {
          eixoText = "3º Eixo";
        }

        return {
          ...model,
          invalid,
          eixoText,
        };
      });
    },
    productHeadersToShow() {
      if (this.search) {
        return this.productHeaders;
      } else {
        const formatted = [...this.productHeaders];
        formatted.splice(2, 0, {
          key: "posicao",
          label: "Posição",
          sortable: true,
          thStyle: {
            width: "130px !important",
          },
        });
        return formatted;
      }
    },
    productsToShow() {
      if (this.search) {
        return this.productsFromApi.map((api) => ({
          ...api,
          remove: this.form.products.find((p) => p.id === api.id),
        }));
      } else {
        return this.form.products;
      }
    },
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    openCombobox() {
      this.comboboxFocus = true;
    },
    closeCombobox() {
      this.comboboxFocus = false;
    },
    resetInputs() {
      this.searchModel = "";
      this.form = {
        modeloId: 0,
        modelText: "",
        eixo: "",
        anoInicial: "",
        anoFinal: "",
        models: this.form.models,
        products: this.form.products,
      };
    },
    addModel() {
      if (this.form.modeloId && this.form.eixo) {
        const model = this.formattedModels.find(
          (model) => model.id === this.form.modeloId
        );

        this.form.models.push({
          modeloId: this.form.modeloId,
          modelText: model.text,
          eixo: this.form.eixo,
          anoInicial: this.form.anoInicial,
          anoFinal: this.form.anoFinal,
          posicao: this.form.posicao,
        });

        this.resetInputs();
      }
    },
    openRemoveModelDialog(id) {
      this.showRemoveModal = true;
      this.idToRemove = id;
    },
    removeAtributo() {
      this.form.models = this.form.models.filter(
        (model) => model.modeloId !== this.idToRemove
      );
      this.showRemoveModal = false;
      this.idToRemove = 0;
    },
    async onSearch() {
      this.isLoadingSearch = true;
      const { data } = await http.get("product/search", {
        params: {
          search: this.search,
          page: 1,
        },
      });
      this.productsFromApi = data;
      this.showButton = data.length === 20;
      this.page = 2;
      this.isLoadingSearch = false;
    },
    async onShowMore() {
      const { data } = await http.get("product/search", {
        params: {
          search: this.search,
          page: this.page,
        },
      });
      this.productsFromApi = [...this.productsFromApi, ...data];
      this.showButton = data.length === 20;

      this.page = this.page + 1;
    },
    addProduct(product) {
      this.form.products = [
        ...this.form.products,
        { ...product, remove: true, posicao: "" },
      ];
    },
    removeProduct(id) {
      this.form.products = this.form.products.filter(
        (product) => product.id !== id
      );
    },
    async onSubmit() {
      try {
        await http.post("/product/vehicle/many-vehicle", {
          models: this.form.models.map((model) => ({
            modeloId: model.modeloId,
            eixo: model.eixo,
            anoInicial: model.anoInicial ? Number(model.anoInicial) : undefined,
            anoFinal: model.anoFinal ? Number(model.anoFinal) : undefined,
            posicao: model.posicao ? Number(model.posicao) : undefined,
          })),
          products: this.form.products.map((product) => ({
            id: product.id,
            posicao: product.posicao ? Number(product.posicao) : undefined,
          })),
        });
        this.resetInputs();
        this.form.products = [];
        this.form.models = [];
        this.setToastedSuccess({
          message: "Produtos relacionados com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.invalid) return "table-danger";
    },
  },
  async created() {
    const [modelRes] = await Promise.all([http.get("/brand/model/combobox")]);
    this.modelOptions = modelRes.data;
  },
};
</script>

<style lang="scss">
.combobox-wrap {
  position: relative;
}

.combobox-list {
  z-index: 9999;
  position: absolute;
  top: 80;
  background-color: white;
  max-height: 320px;
  width: 100%;
  overflow-y: scroll;
  padding: none;
  border: 1px solid #ced4da;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

.combobox-item {
  padding: 0px 12px;

  &:hover {
    background-color: rgb(86, 86, 207);
    color: white;
    cursor: pointer;
  }
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}
</style>
