<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex flex-column justify-content-center">
            <h3>Marcas e Modelos</h3>
          </div>
        </div>

        <b-tabs fill>
          <b-tab title="MARCAS" class="mt-3">
            <list-brand />
          </b-tab>
          <b-tab title="MODELOS" class="mt-3">
            <list-models />
          </b-tab>
          <b-tab title="RELACIONAR PRODUTOS" class="mt-3">
            <add-to-product />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ListBrand from "./listBrand.vue";
import ListModels from "./listModels.vue";
import AddToProduct from "./addToProduct.vue";

export default {
  name: "list",
  components: { ListBrand, ListModels, AddToProduct },
};
</script>
