<template>
  <div>
    <b-table
      class="lvj-table"
      :items="listBrand"
      :fields="fields"
      bordered
      striped
      hover
      caption-top
    >
      <template #table-caption>
        <search-input
          v-model="searchText"
          full
          :is-loading-search="isLoadingSearch"
          @enter="search"
        />
      </template>
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          @click="openCreateModal"
        >
          NOVO
        </b-button>
      </template>

      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="openEditModal(row.item)"
        >
          <b-icon icon="pencil" />
        </b-button>

        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveModal(row.item.id)"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>

    <b-button v-if="showLoadMore" block class="mb-5" @click="getMore">
      <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
      CARREGAR MAIS
    </b-button>

    <b-modal
      id="modal-center"
      :title="form.id ? 'Editar Marca' : 'Nova Marca'"
      centered
      hide-footer
      v-model="showCreateModal"
    >
      <b-form @submit="onSubmit">
        <form-input
          v-model="form.descricao"
          label="Descrição:"
          placeholder="Digite a descrição da marca"
          required
        />

        <b-button type="submit" variant="primary">SALVAR</b-button>
      </b-form>
    </b-modal>

    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja apagar marca"
      @confirm="onDeleteBrand"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import CustomModal from "../../components/shared/CustomModal.vue";
import SearchInput from "../../components/shared/SearchInput.vue";
import http from "../../plugins/http";

export default {
  name: "listBrand",
  components: { FormInput, CustomModal, SearchInput },
  data() {
    return {
      searchText: "",
      listBrand: [],
      form: {
        descricao: "",
      },
      showCreateModal: false,
      showRemoveModal: false,
      brandId: 0,
      isLoading: false,
      page: 2,
      showLoadMore: true,
      isLoadingSearch: false,
      fields: [
        {
          key: "descricao",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    openCreateModal() {
      this.form = {
        descricao: "",
      };
      this.showCreateModal = true;
    },
    async onCreateBrand() {
      try {
        const { data } = await http.post("/brand", this.form);
        this.listBrand = [...this.listBrand, data];

        this.setToastedSuccess({ message: "Marca criada com sucesso" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao criar marca, tente novamente mais tarde",
        });
      }
    },
    openEditModal(brand) {
      this.form = brand;
      this.showCreateModal = true;
    },
    async onUpdateBrand() {
      try {
        const { data } = await http.put(`/brand/${this.form.id}`, this.form);
        const index = this.listBrand.findIndex(
          (brand) => brand.id === this.form.id
        );
        const brandsCopy = [...this.listBrand];
        brandsCopy.splice(index, 1, data);

        this.listBrand = brandsCopy;

        this.setToastedSuccess({ message: "Marca editada com sucesso" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao editar marca, tente novamente mais tarde",
        });
      }
    },
    openRemoveModal(id) {
      this.brandId = id;
      this.showRemoveModal = true;
    },
    async onDeleteBrand() {
      try {
        await http.delete(`/brand/${this.brandId}`);

        this.listBrand = this.listBrand.filter(
          (brand) => brand.id !== this.brandId
        );

        this.setToastedSuccess({ message: "Marca removida com sucesso" });
      } catch (err) {
        this.setToastedError({
          message: "Erro ao removida marca, tente novamente mais tarde",
        });
      }
      this.showRemoveModal = false;
    },
    async onSubmit(event) {
      event.preventDefault();

      if (this.form.id) {
        await this.onUpdateBrand();
      } else {
        await this.onCreateBrand();
      }

      this.showCreateModal = false;
    },
    async search() {
      this.isLoadingSearch = true;
      this.page = 2;

      const { data } = await http.get("/brand", {
        params: {
          search: this.searchText,
        },
      });

      this.listBrand = data;
      this.isLoadingSearch = false;

      this.showLoadMore = data.length === 20;
    },
    resetSearch() {
      this.searchInput = "";
      this.page = 2;
      this.getMore();
    },
    async getMore() {
      this.isLoading = true;
      const { data } = await http.get("/brand", {
        params: {
          page: this.page,
        },
      });

      this.listBrand = [...this.listBrand, ...data];
      this.isLoading = false;

      if (data.length === 20) {
        this.page = this.page + 1;
      } else {
        this.showLoadMore = false;
      }
    },
  },
  async created() {
    const { data } = await http.get("/brand");
    if (data.length !== 20) this.showLoadMore = false;
    this.listBrand = data;
  },
};
</script>
